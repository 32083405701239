import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import { HistoryLocation } from '@reach/router'
import useGlobalText from '@system/hooks/useGlobalText'

import JobDetail from '@components/modules/content/M009-JobDetail'
import Contact from '@components/modules/content/M003-Contact'
import { TeaserPageProps } from '@components/modules/content/M017-Teaser'

export interface JobPageProps {
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  data: {
    job: {
      id: string
      name: string
      slug: string
      jobId: string
      department?: string
      schedule?: string
      type?: string
      jobDescriptions?: Array<{
        name: string
        value: string
      }>
      officePage?: TeaserPageProps
    }
  }
}

export default function JobDetailPage({
  data,
  pageContext,
}: JobPageProps): ReactElement {
  const job = data.job
  const { getText } = useGlobalText()

  return (
    <>
      <JobDetail
        pageContext={pageContext}
        theme="light"
        name={job.name}
        slug={job.slug}
        department={job.department}
        schedule={job.schedule}
        jobDescriptions={job.jobDescriptions}
      />

      <Contact
        pageContext={pageContext}
        theme="light"
        type="social"
        headline={getText('page.jobDetail.contact.headline')}
        socialNetwork={['LinkedIn', 'XING', 'Facebook', 'Twitter', 'E-Mail']}
      />
    </>
  )
}

export const pageQuery = graphql`
  query JobDetailBySlugID($id: String!, $locale: String!) {
    job(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      name
      slug
      jobId
      department
      schedule
      type
      jobDescriptions {
        name
        value
      }
    }
  }
`
