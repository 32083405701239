import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import { HistoryLocation } from '@reach/router'

import ApplicationForm from '@components/core/forms/applicationForm'

export interface JobPageProps {
  location: HistoryLocation
  pageContext: DBN.PageHelpers.PageContext
  data: {
    job: {
      id: string
      name: string
      slug: string
      jobId: string
      unit?: string
      office?: string
      department?: string
      jobDescriptions?: Array<{
        name: string
        value: string
      }>
    }
  }
}

export default function JobApplicationPage({
  data,
}: JobPageProps): ReactElement {
  return <ApplicationForm job={data.job} />
}

export const pageQuery = graphql`
  query JobApplicationBySlugID($id: String!, $locale: String!) {
    job(id: { eq: $id }, node_locale: { eq: $locale }) {
      id
      name
      slug
      jobId
      unit
      office
      department
      jobDescriptions {
        name
        value
      }
    }
  }
`
